<template>
    <div class="main">
        <!-- &lt;!&ndash;        百度地图bmap承接div&ndash;&gt; -->
        <div class="bm-view" id="allmap"></div>
        <div class="tabBox" v-show="tabBox">
            <el-radio-group v-model="All" @change="changeTab()">
                <el-radio-button v-for="option in tabAll" :label="option" :key="option">{{ option }}
                </el-radio-button>
            </el-radio-group>
            <div class="content">
                <div class="second-tab second-tab-traffic" v-show="All=='小区'" @change="search('house')">
                    <div class="second-tab-select">
                        <el-radio-group v-model="around.house">
                            <el-radio-button v-for="option in aroundoptions.house" :label="option"
                                             :key="option">{{ option }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="second-tab second-tab-traffic" v-show="All=='交通'" @change="search('tabTraffic')">
                    <div class="second-tab-select">
                        <el-radio-group v-model="around.tabTraffic">
                            <el-radio-button v-for="option in aroundoptions.tabTraffic" :label="option"
                                             :key="option">{{ option }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="second-tab second-tab-edu" v-show="All=='学校'" @change="search('tabEdu')">
                    <div class="second-tab-select">
                        <el-radio-group v-model="around.tabEdu">
                            <el-radio-button v-for="option in aroundoptions.tabEdu" :label="option" :key="option">
                                {{ option }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="second-tab second-tab-medical" v-show="All=='医院'" @change="search('tabMedical')">
                    <div class="second-tab-select">
                        <el-radio-group v-model="around.tabMedical">
                            <el-radio-button v-for="option in aroundoptions.tabMedical" :label="option"
                                             :key="option">{{ option }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="second-tab second-tab-shop" v-show="All=='商业'" @change="search('tabShop')">
                    <div class="second-tab-select">
                        <el-radio-group v-model="around.tabShop">
                            <el-radio-button v-for="option in aroundoptions.tabShop" :label="option" :key="option">
                                {{ option }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
              
                <div class="second-tab second-tab-amusement" v-show="All=='娱乐'"
                     @change="search('tabAmusement')">
                    <div class="second-tab-select">
                        <el-radio-group v-model="around.tabAmusement">
                            <el-radio-button v-for="option in aroundoptions.tabAmusement" :label="option"
                                             :key="option">{{ option }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="result">
                    <div class="emptyData" v-show="!searchResult">
                        <div>很抱歉，该项目周边3KM未查找到相关设施，请查看其他周边设施</div>
                    </div>
                    <ul class="hasData" v-show="searchResult">
                        <li class="data-list" v-for="(item,index) in searchResult" :key="index" @click="locateAround(item)">
                            <div class="content-box">
                                <div class="item-content">
                                    <div class="icon1">{{index+1}}</div>
                                    <div class="name" :title="item.name||item.nearbyProjectName" ref="namehover" @mouseover="mouseOver(index)"
                                         @mouseleave="mouseLeave(index)">{{ item.name||item.nearbyProjectName }}
                                    </div>
                                    <div class="icon-distance"></div>
                                    <span class="distance" v-if="item.location">{{ distanceCompute(item.location) }}米</span>
                                    <span class="distance" v-if="item.distance">{{ item.distance }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- &lt;!&ndash;        左上角周边查询&ndash;&gt; -->
        <!-- <div id="left-panel">
            <div class="searchAround">
                <input placeholder="查周边" v-model="keyword" @keyup.enter="keySearch(keyword)" />
                <button id="search-button" data-title="搜索" @click="keySearch(keyword)"></button>
            </div>
            <div id="keySearchResult" class="searchResult" v-show="keySearchResultShow"></div>
        </div> -->
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {getStore,setStore,getLocalStore} from "@/util/store";
//提前全局声明BMap，map，方便后续方法使用BMap.XX方法
let bmapPromise;
let BMap = {};
// let BMapLib = {};
let map = {};
export default {
    name: "index",
    data() {
        return {
            proname: getStore('thisProject').proName,
            //中心点图形
            proImg: require('../../assets/img/map_icon_nowlocation.png'),
            //右下角快捷搜索是否显示
            tabBox: true,
            //中心点
            center: {
                lng: null,
                lat: null
            },
            //当前缩放比例
            ZoomNum: 16,
            //初始化缩放比例
            zoom: 16,
            //上方搜索栏输入的关键字
            keyword: '',
            //当前大类
            All: '小区',
            //当前各小类
            around: {
                house: '小区',
                tabTraffic: '公交站',
                tabEdu: '幼儿园',
                tabMedical: '医院',
                tabShop: '超市',
            },
            //大类数组
            tabAll: ['小区','交通', '商业', '学校', '医院'],
            //小类数组
            aroundoptions: {
                house: ['住宅'],
                tabTraffic: ['公交站', '地铁站'],
                tabEdu: ['幼儿园', '小学', '中学', '大学'],
                tabMedical: ['医院', '药店'],
                tabShop: ['超市', '商城', '市场'],
            },
            searchResult: {},
            keySearchResult: {},
            keySearchResultShow: false,
            nowIcon: 'zb-bus',//周边-公交站
            //当前标点图形
            dotIcon: require('../../assets/img/map_icon.png'),
            //地图实例
            mapObj : '',
            isType: 'house'
        }
    },
    computed: {},
    created() {
        //获取地图中心、异步加载bmapjs文件
        this.getMapCenter();
        this.loadBmapJS();
        this.getReport(0,'','');
    },
    destroyed(){
        this.getReport(1,'','');
    },
    mounted() {
        //点击页面其他元素收起搜索栏
        document.addEventListener('click', (e) => {
            let thisClass = e.target.className
            if (thisClass !== 'searchResult' || thisClass !== 'searchAround') {
                this.keySearchResultShow = false
            }
        });
        setTimeout(() => {
            //初始化地图、默认首页查找地铁站
            this.initMap();
        }, 1000)
    },
    watch: {
        //监听ZoomNum（当前地图缩放级别），小于等于13 清除标志物
        ZoomNum() {
            if (this.ZoomNum > 13) {
                if(this.isType == "house"){
                    this.setHouseResult()
                }else{
                    this.setSearchResult()
                }
            } else {
                map.clearOverlays() //bmap api 清除标志物
            }
        },
        searchResult() {
            if (this.searchResult == '') map.clearOverlays()
        },
        //监听当前搜索选择的标签，动态改变dotIcon图片路径
        // nowIcon() {
        //     this.dotIcon = require('../../assets/img/map_icon_' + this.nowIcon + '.png')
        // },
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //返回上级页面
        back() {
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            var currentMenu = getStore("currentMenu");
            this.$router.push({path: currentMenu});
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'mapAround',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //右侧快捷搜索功能块鼠标移入效果
        mouseOver(index) {
            // 操作dom 获取p标签改变其样式
            this.$refs.namehover[index].style.color = "#185BCE";
        },
        //右侧快捷搜索功能块鼠标移出效果
        mouseLeave(index) {
            this.$refs.namehover[index].style.color = '#323233';
        },
        //加载百度地图api
        loadBmapJS() {
            if (window.BMap) {
                return Promise.resolve();
            }
            if (bmapPromise) {
                return bmapPromise;
            }
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = 'https://api.map.baidu.com/api?v=3.0&ak=jMnfAv2MlXEUmzzDjjGvlmXrpj0TP66a&callback=initMap'
         
            document.body.appendChild(script);
            bmapPromise = new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                    // this.loadBmapLibJS();
                };
                script.onerror = error => {
                    reject(error)
                }
            })

        },
        loadBmapLibJS() {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "http://api.map.baidu.com/library/RichMarker/1.2/src/RichMarker_min.js";
            // script.onload = () => {
            //     this.initMap();
            // }
             bmapPromise = new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                    this.initMap();
                };
                script.onerror = error => {
                    reject(error)
                }
            })
            document.body.appendChild(script);
        },
        //初始化地图
        initMap() {

            BMap = window.BMap;
            map = new BMap.Map('allmap');
            this.mapObj = map;
            //初始化地图中心、层级
            map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), this.zoom);
            //允许地图鼠标滚轮缩放层级
            map.enableScrollWheelZoom(true);
            //定义右上角百度地图工具栏 平移和缩放按钮
            var top_right_navigation = new BMap.NavigationControl({
                anchor: BMAP_ANCHOR_TOP_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            });
            map.addControl(top_right_navigation);
            //百度全景地图
            var stCtrl = new BMap.PanoramaControl({
                anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            });  
            stCtrl.setOffset(new BMap.Size(20, 20));  
            map.addControl(stCtrl);
            map.addControl(new BMap.MapTypeControl({
                mapTypes:[
                    BMAP_NORMAL_MAP,
                    BMAP_HYBRID_MAP
                ]}));	  
            // var navi3DCtrl = new BMap.NavigationControl3D();  // 添加3D控件
            // map.addControl(navi3DCtrl);
            //监听地图缩放，赋值到ZoomNum
            map.addEventListener("zoomend", (e) => {
                this.ZoomNum = e.target.getZoom()
            });
            // 创建点
            let myIcon = new BMap.Icon(this.proImg, new BMap.Size(32, 32));
            let mpoint = new BMap.Point(this.center.lng, this.center.lat);
            let marker = new BMap.Marker(mpoint, {icon: myIcon});
            map.addOverlay(marker);    //增加点
            marker.addEventListener("click", () => {

            });
            //中心点标签
            let cont = "<span class='markername' style='background-color:rgba(255,255,255,.8);border:1px solid #498AFF'>" + this.proname + "</span><span id='arrows'></span>";
            let label = new BMap.Label(cont, {offset: new BMap.Size(0, -15)});
            marker.setLabel(label);
            marker.disableMassClear(); //不被clearOverlay清除
            this.search('house');
        },
        //取得地图中心坐标
        getMapCenter() {
            this.center.lng = getStore('thisProject').longitude;
            this.center.lat = getStore('thisProject').latitude;
            // this.center.lng = '118.321';
            // this.center.lat = '40.153';
        },
        //计算距离中心点直线距离
        distanceCompute(location) {
            // BMap = window.BMap;
            map = this.mapObj;
            let p1 = [parseFloat(this.center.lng),parseFloat(this.center.lat)]
            let p2 = [location.lng,location.lat]
            let start = new BMap.Point(p1[0], p1[1]);    //起点
            let end = new BMap.Point(p2[0], p2[1]);    //终点
            let d= map.getDistance(start, end).toFixed(2)
            return parseInt(parseInt(d));
        },
        //大小标签改变时触发
        changeTab() {
            let bigtype //当前选择的二级标签
            let index = this.tabAll.indexOf(this.All)
            let secondArr = ['house','tabTraffic','tabShop','tabEdu','tabMedical']
            bigtype = secondArr[index];
            if(bigtype=="house"){
                this.isType = 'house';
                this.houseAxios()
            }else{
                this.isType = 'other';
                let type = this.around[bigtype]  //当前的小类
                this.dataAxios(type)
            }
        },
        keySearch(keyword) {  //存疑
            let center = this.center.lng + ',' + this.center.lat;
            this.keySearchResultShow = true
            map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), 15);
            let local = new BMap.LocalSearch(map, {
                renderOptions: {map: map, panel: "keySearchResult"}
            });
            local.searchNearby(keyword, center, 3000);
        },
        mapCallback(res){
        },
        dataAxios(query){
            let location = this.center.lat + ',' + this.center.lng
            this.$jsonp(
                'https://api.map.baidu.com/place/v2/search?callback=mapCallback&output=json&query='+query+'&location='+location+'&radius=3000&page_size=20&ak=jMnfAv2MlXEUmzzDjjGvlmXrpj0TP66a'
            ).then((res)=>{
                if (res.results == '') {
                    this.searchResult = '';
                } else {
                    this.searchResult = res.results;
                    this.setSearchResult();
                }
            })
        },
        houseAxios(){
            this.axios.request({
                loading: true,
                method: 'get',
                url: '/dms/nearbyProject',
                 
                params: {
                    projectId: getStore("thisProject").id,
                    // projectId: '988823',
                }
            }).then((res) => {
                if (res.data.data.length==0) {
                    this.searchResult = '';
                    this.isType = 'other';
                    this.dataAxios('住宅');
                } else {
                    this.searchResult = res.data.data;
                    this.setHouseResult();

                    //判断小区周边地址与该项目经纬度差值距离
                    let lngNum = Math.abs(this.center.lng - res.data.data[0].longitude);
                    if(lngNum>0.1){
                        this.$message.warning('该项目经纬度信息偏差过大，请在项目信息中修改后再使用');
                    }
                }
            })
        },
        search(type) {
            if(type=="house"){
                this.isType = 'house';
                this.houseAxios()
            }else{
                this.isType = 'other';
                let query = this.around[type];
                this.dataAxios(query)
            }
            
        },
        //给小区列表创建节点地图
        setHouseResult(icon) {
            map.clearOverlays()
            let result

            let pointList = [];
            let markerList = [];
            let myIcon;
            if (icon == false) {
                result = this.keySearchResult
                myIcon = new BMap.Icon(new BMap.Size(28, 45));
            } else {
                result = this.searchResult
                myIcon = new BMap.Icon(this.dotIcon, new BMap.Size(28, 45));
            }
            myIcon.setAnchor(new BMap.Size(32, 32));
            for (let i = 0; i < result.length; i++) {
                let index = i+1;
                let iconSrc = '../../assets/img/map_icon.png'
                let htm = '<div style="width: 28px;height: 45px;color:#fff;text-align:center;padding-top:8px;box-sizing:border-box;background: url('+this.dotIcon+');">'+index+'</div>'
                pointList[i] = new BMap.Point(result[i].longitude, result[i].latitude);
                markerList[i] = new BMap.Marker(pointList[i], {icon: myIcon});
                // markerList[i] = new BMapLib.RichMarker(htm, pointList[i],{"anchor":new BMap.Size(-25, -20)});
                markerList[i].id = result[i].id;
                map.addOverlay(markerList[i]);

                
                //点击标注显示悬浮窗
                let point = new BMap.Point(result[i].longitude, result[i].latitude);
                let content =
                    '<div class="search-label">' +
                    // '<div class="title" style="font-size: 16px;margin-top: 5px">' + result[i].price+ '</div>' +
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">名称：' + result[i].nearbyProjectName + '</div>' +
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">位置：' + result[i].address + '</div>' +
                    '</div>';
                let opt = {
                    width: 286,
                    height: 105,
                    message: result[i].address,
                    offset: new BMap.Size(-20, -20)
                }
                let infoWindow = new BMap.InfoWindow(content, opt)
                markerList[i].addEventListener("click", function(){   
                    // map.centerAndZoom(point, 16);
                    map.openInfoWindow(infoWindow, pointList[i]);
                });
            }

            

        },
        //给查询的周边结果创建节点地图
        setSearchResult(icon) {
            map.clearOverlays()
            let result

            let pointList = [];
            let markerList = [];
            let myIcon;
            if (icon == false) {
                result = this.keySearchResult
                myIcon = new BMap.Icon(new BMap.Size(28, 45));
            } else {
                result = this.searchResult
                myIcon = new BMap.Icon(this.dotIcon, new BMap.Size(28, 45));
            }
            myIcon.setAnchor(new BMap.Size(32, 32));
            for (let i = 0; i < result.length; i++) {
                pointList[i] = new BMap.Point(result[i].location.lng, result[i].location.lat);
                markerList[i] = new BMap.Marker(pointList[i], {icon: myIcon});
                let htm = '<div style="width: 28px;height: 45px;color:#fff;text-align:center;padding-top:8px;box-sizing:border-box;background: url('+this.dotIcon+');">'+(+i+1)+'</div>'
                // markerList[i] = new BMapLib.RichMarker(htm, pointList[i],{"anchor":new BMap.Size(-25, -20)});
                markerList[i].id = result[i].uid;
                map.addOverlay(markerList[i]);

                //点击标注显示悬浮窗
                let point = new BMap.Point(result[i].location.lng, result[i].location.lat);
                let content =
                    '<div class="search-label">' +
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">名称：' +  result[i].name + '</div>' +
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">位置：' + result[i].address + '</div>' +
                    '</div>';
                let opt = {
                    width: 286,
                    height: 80,
                    message: result[i].address,
                    offset: new BMap.Size(-20, -20)
                }
                let infoWindow = new BMap.InfoWindow(content, opt)
                markerList[i].addEventListener("click", function(){   
                    // map.centerAndZoom(point, 16);
                    map.openInfoWindow(infoWindow, point);
                });
            }
            

        },
        locateAround(e) {
            let lng,lat,name,content,height;
            if(this.isType=='house'){
                lng = e.longitude;
                lat = e.latitude;
                name = e.nearbyProjectName;
                height = 105;
                content =
                    '<div class="search-label">' +
                    // '<div class="title" style="font-size: 16px;margin-top: 5px">' + e.price+ '</div>' +
                    // '<div style="position: absolute;right: 0;top: 4px;color: #185BCE;cursor: pointer;">历年房价</div>'+
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">名称：' + name + '</div>' +
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">位置：' + e.address + '</div>' +
                    '</div>';
            }else{
                lng = e.location.lng;
                lat = e.location.lat;
                name = e.name;
                height = 80;
                content =
                    '<div class="search-label">' +
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">名称：' + name + '</div>' +
                    '<div class="title" style="font-size: 14px;color: #646566;margin-top: 8px">位置：' + e.address + '</div>' +
                    '</div>';
            }
            let point = new BMap.Point(lng, lat);
            map.centerAndZoom(point, 16);
            
            let opt = {
                width: 286,
                height: height,
                message: e.address,
                offset: new BMap.Size(-20, -20)
            }
            let infoWindow = new BMap.InfoWindow(content, opt)
            setTimeout(() => {
                map.openInfoWindow(infoWindow, point);
            }, 100);
           
        },
    }
}
</script>

<style scoped lang="less">

.main {
    position: relative;
    width: 100%;
    height: 100%;

    .bm-view {
        position: relative;
        width: 100%;
        height: 100%;
    }

    /deep/ .BMapLabel {
        .markername {
            padding: 5px;
            display: block;
            height: 40px;
            line-height: 40px;
            width: 120px;
            text-align: center;
            border: 1px solid #cccccc;
            background-color: #fff;
            border-radius: 3px;
            font-weight: bold;
            font-size: 14px;
            box-shadow: 0 3px 14px rgba(0, 0, 100, .6);
            position: absolute;
            top: -41px;
            left: -47px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        #arrows {
            background: url(../../assets/img/marksArrow.png) no-repeat center center;
            height: 11px !important;
            position: absolute;
            width: 30px;
            top: 10px;
            left: 0;
        }
    }

    .slide-fade-enter-active {
        transition: all .2s ease;
    }

    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(5px);
        opacity: 0;
    }

    .back {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 10px;
        left: 10px;
        background: url(../../assets/img/icon_back.png) no-repeat;
        cursor: pointer;
    }

    .left_jtou {
        position: absolute;
        top: calc(50% - 30px);
        left: 320px;
        background: url(../../assets/img/icon_jty.png) no-repeat;
        width: 14px;
        height: 60px;
        cursor: pointer;
        z-index: 2;
    }

    .left_jtou.hide {
        left: 0;
        background: url(../../assets/img/icon_yjt.png) no-repeat;
    }

    .hide_tabBox {
        position: absolute;
        top: calc(50% - -100px);
        right: 30px;
        background: url(../../assets/img/icon_yjt.png) no-repeat;
        width: 14px;
        height: 60px;
        cursor: pointer;
        z-index: 4;
    }

    .hide_tabBox.hide {
        right: 30px;
        background: url(../../assets/img/icon_jty.png) no-repeat;
    }

    .tabBox {
        position: absolute;
        left: 16px;
        top: 16px;
        bottom: 16px;
        width: 340px;
        z-index: 2;

        .el-radio-group {
            width: 100%;

            /deep/ .el-radio-button__inner {
                border: none;
                border-radius: 0;
            }
        }

        .content {
            height: calc(100% - 60px);
            width: 100%;
            background-color: #fff;

            .second-tab {
                .second-tab-select {
                    border-bottom: 1px solid #C8C9CC;

                    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                        background-color: #FFFFFF;
                        color: #409EFF;
                        border-bottom: 1px solid #409EFF;
                        box-shadow: none;
                        border-radius: 0;
                    }

                }
            }

            .result {
                width: 100%;
                height: calc(100% - 40px);
                overflow-y: hidden;

                .emptyData {
                    width: 100%;
                    height: 100%;
                    font-size: 16px;

                    div {
                        position: relative;
                        width: 80%;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: auto;
                    }
                }

                ul.hasData {
                    overflow-y: scroll;
                    width: 100%;
                    height: 100%;

                    li.data-list {
                        height: 52px;
                        width: 100%;
                        line-height: 52px;
                        cursor: pointer;

                        .content-box {
                            height: 100%;

                            .item-content {
                                .zb-sub {
                                    background: url(../../assets/img/map_icon_zb-sub.png) no-repeat;
                                }

                                .zb-bus {
                                    background: url(../../assets/img/map_icon_zb-bus.png) no-repeat;
                                }

                                .zb-yey {
                                    background: url(../../assets/img/map_icon_zb-yey.png) no-repeat;
                                }

                                .zb-xx {
                                    background: url(../../assets/img/map_icon_zb-xx.png) no-repeat;
                                }

                                .zb-zx {
                                    background: url(../../assets/img/map_icon_zb-zx.png) no-repeat;
                                }

                                .zb-dx {
                                    background: url(../../assets/img/map_icon_zb-dx.png) no-repeat;
                                }

                                .zb-yy {
                                    background: url(../../assets/img/map_icon_zb-yy.png) no-repeat;
                                }

                                .zb-yd {
                                    background: url(../../assets/img/map_icon_zb-yd.png) no-repeat;
                                }

                                .zb-sc {
                                    background: url(../../assets/img/map_icon_zb-sc.png) no-repeat;
                                }

                                .zb-cs {
                                    background: url(../../assets/img/map_icon_zb-cs.png) no-repeat;
                                }

                                .zb-sch {
                                    background: url(../../assets/img/map_icon_zb-sch.png) no-repeat;
                                }

                                .zb-yh {
                                    background: url(../../assets/img/map_icon_zb-yh.png) no-repeat;
                                }

                                .zb-atm {
                                    background: url(../../assets/img/map_icon_zb-atm.png) no-repeat;
                                }

                                .zb-ct {
                                    background: url(../../assets/img/map_icon_zb-ct.png) no-repeat;
                                }

                                .zb-cfg {
                                    background: url(../../assets/img/map_icon_zb-cfg.png) no-repeat;
                                }

                                .zb-gy {
                                    background: url(../../assets/img/map_icon_zb-gy.png) no-repeat;
                                }

                                .zb-dyy {
                                    background: url(../../assets/img/map_icon_zb-dyy.png) no-repeat;
                                }

                                .zb-jsf {
                                    background: url(../../assets/img/map_icon_zb-jsf.png) no-repeat;
                                }

                                .zb-tyg {
                                    background: url(../../assets/img/map_icon_zb-tyg.png) no-repeat;
                                }

                                .icon1 {
                                        width: 22px;
                                        height: 22px;
                                        background: #D40000;
                                        border-radius: 22px;
                                        margin-left: 16px;
                                        margin-top: 14px;
                                        position: relative;
                                        float: left;
                                        line-height: 24px;
                                        color: #FFFFFF;
                                        text-align: center;
                                }

                                .name {
                                    width: 60%;
                                    height: 25px;
                                    line-height: 25px;
                                    vertical-align: middle;
                                    display: inline-block;
                                    margin-left: 5px;
                                    font-weight: bold;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .icon-distance {
                                    width: 16px;
                                    height: 16px;
                                    display: inline-block;
                                    position: relative;
                                    margin-left: 10px;
                                    top: 4px;
                                    background: url(../../assets/img/map_icon_dis.png) no-repeat;
                                }

                                .distance {
                                    color: #969799;
                                    margin-left: 5px;
                                    display: inline-block;
                                }
                            }
                            .item-content:hover{
                                background: #EDF4FF;
                                .icon1{
                                    background: #185BCE;
                                }
                            }
                            .item-info {
                                display: block;
                                width: 80%;
                                margin: 6px auto;
                            }
                        }
                    }

                }
            }
        }


    }

    .left_menu {
        width: 320px;
        position: absolute;
        height: calc(100% - 16px);
        left: 0;
        top: 8px;
        background-color: #fff;
        z-index: 99;
        box-shadow: 0 0 10px #9f9f9f;

        .xmss-cont {
            height: 42px;
            line-height: 40px;
            border-bottom: 1px solid #D1DEEC;
            padding: 0 10px;

            input.xmname {
                width: calc(100% - 28px);
                height: 24px;
                border: solid 1px #3492FF;
                font-size: 12px;
                text-indent: 10px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 2px 0 0 2px;
                outline: none;
            }

            .ss-an-btn {
                height: 26px;
                width: 26px;
                margin-left: -5px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 0 2px 2px 0;
                background: #3492FF url(../../assets/img/ss-jsdw.png) no-repeat center center;
            }
        }


        h3 {
            height: 40px;
            line-height: 40px;
            text-indent: 10px;
            font-size: 12px;
            float: right
        }

        .sx-box {
            height: 40px;

            .sx-box-left {
                float: left;
                font-weight: normal;
                color: #000;
                font-size: 16px
            }

            .sx-box-right {
                font-weight: normal;
                text-align: right;
                padding-right: 10px;

                .znum {
                    color: #3492FF;
                    font-weight: bold;
                    font-size: 14px;
                    margin: 0 3px;
                }
            }
        }

        .sx-tips {
            height: 70px;
            border-bottom: solid 1px #E5EEF7;

            .tips-item {
                padding: 2px 10px;
                border: 1px solid #1267FF;
                box-sizing: border-box;
                color: #1267FF;
                font-size: 12px;
                text-align: center;
                line-height: 21px;
                margin-left: 5px;
                border-radius: 5px;
                cursor: pointer;
                float: left;
                margin-bottom: 5px;
            }

            .tips-item.hover {
                background-color: #1267FF;
                color: #fff;
            }

            .tips-item.wddqstj {
                border-color: #9188E5;
                color: #9188E5;
            }

            .tips-item.wddqstj.hover {
                background-color: #9188E5;
                color: #fff;
            }

            .tips-item.kqs {
                border-color: #FFA149;
                color: #FFA149;
            }

            .tips-item.kqs.hover {
                background-color: #FFA149;
                color: #fff;

            }

            .tips-item.yqs {
                border-color: #FF7C6B;
                color: #FF7C6B;
            }

            .tips-item.yqs.hover {
                background-color: #FF7C6B;
                color: #fff;

            }

            .tips-item.qsz {
                border-color: #498AFF;
                color: #498AFF;
            }

            .tips-item.qsz.hover {
                background-color: #498AFF;
                color: #fff;
            }

            .tips-item.qswc {
                border-color: #3BCFA8;
                color: #3BCFA8;
            }

            .tips-item.qswc.hover {
                background-color: #3BCFA8;
                color: #fff;
            }
        }

        .menu_list {
            height: calc(100% - 183px);

            ul li {
                border-bottom: solid 1px #E5EEF7;
                padding: 10px 40px 10px 20px;
                position: relative;

                div {
                    padding: 5px 0;
                    line-height: 16px;
                }

                div.li_title {
                    color: #3492FF;
                    font-size: 14px;
                }

                div.li_adree {
                    font-size: 12px;
                    line-height: 20px;
                }

                div.li_xq {
                    position: absolute;
                    width: 20px;
                    height: 16px;
                    background: url(../../assets/img/icon_mapxq.png) no-repeat;
                    top: 15px;
                    right: 10px;
                }
            }

            ul li.cur {
                background-color: #F8F8F8;
            }
        }
    }

    #left-panel {
        margin-top: 40px;
        margin-left: 11px;
        max-height: 500px;
        overflow-y: scroll;

        ::-webkit-scrollbar {
            width: 1px
        }

        .searchAround {
            position: absolute;
            display: flex;
            top: 10px;
            left: 70px;
            height: 38px;
            width: 400px;

            input {
                width: 350px;
                box-sizing: border-box;
                border: 0;
                padding: 9px 0;
                border-left: 10px solid transparent;
                border-right: 27px solid transparent;
                line-height: 20px;
                font-size: 16px;
                height: 38px;
                color: #333;
                position: relative;
                border-radius: 2px 0 0 2px;
                outline: none; /*去掉选中边框*/
            }

            #search-button {
                pointer-events: auto;
                background: url(//webmap1.bdimg.com/wolfman/static/common/images/new/searchbox_5c0d97d.png) no-repeat 0 -76px #3385ff;
                width: 57px;
                height: 38px;
                float: left;
                border: 0;
                padding: 0;
                cursor: pointer;
                border-radius: 0 2px 2px 0;
                box-shadow: 1px 2px 1px rgb(0 0 0 / 15%);
            }
        }

        .searchResult {
            position: absolute;
            top: 60px;
            left: 70px;
            max-height: 500px;
            height: auto;
            width: 400px;
            background-color: #fff;
            overflow-y: scroll;

            ul {
                height: 100%;
                width: 100%;

                li {
                    position: relative;
                    height: 60px;
                    width: 80%;
                    border-bottom: 1px solid #C8C9CC;
                    margin: 10px auto;
                    cursor: pointer;
                }

            }
        }
    }

    .BMap_noprint {
        margin: 10px 20px;
    }
    .btn-price{
        position: absolute;
        right: 0;
        top: 0;
        color: #185BCE;
    }
   
}

</style>
